import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import $ from 'jquery';
import axios from 'axios'
import moment from 'moment' //导入文件
Vue.prototype.$moment = moment;//赋值使用
Vue.prototype.$axios = axios;
window.jQuery = $;
window.$ = $;

Vue.config.productionTip = false;
// Vue.prototype.$EventBus = new Vue()
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
